define("ember-animated/motions/box-shadow", ["exports", "ember-animated", "ember-animated/box-shadow"], function (_exports, _emberAnimated, _boxShadow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.BoxShadowMotion = void 0;
  _exports.default = boxShadow;

  function boxShadow(sprite, opts) {
    return new BoxShadowMotion(sprite, opts).run();
  }

  class BoxShadowMotion extends _emberAnimated.Motion {
    *animate() {
      let from;

      if (this.opts.from) {
        from = _boxShadow.BoxShadow.fromUserProvidedShadow(this.opts.from);
      } else {
        from = _boxShadow.BoxShadow.fromComputedStyle(this.sprite.initialComputedStyle['box-shadow']);
      }

      let to;

      if (this.opts.to) {
        to = _boxShadow.BoxShadow.fromUserProvidedShadow(this.opts.to);
      } else {
        to = _boxShadow.BoxShadow.fromComputedStyle(this.sprite.finalComputedStyle['box-shadow']);
      }

      let shadowTween = new _boxShadow.BoxShadowTween(from, to, this.duration, this.opts.easing);

      while (!shadowTween.done) {
        this.sprite.applyStyles({
          'box-shadow': shadowTween.currentValue.map(shadow => shadow.toString()).join(',')
        });
        yield (0, _emberAnimated.rAF)();
      }
    }

  }

  _exports.BoxShadowMotion = BoxShadowMotion;
});