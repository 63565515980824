define("ember-animated-tools/components/time-control", ["exports", "@ember/component", "ember-animated-tools/templates/components/time-control", "ember-animated-tools/reexported/time-control", "@ember/object", "@ember/template", "ember-animated", "@ember/service"], function (_exports, _component, _timeControl, _timeControl2, _object, _template, _emberAnimated, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function toLogSpeed(speedPercent) {
    let percent = speedPercent;

    if (percent <= 1) {
      // Log of zero is infinity. Log of 1% is as low as we go, which
      // counts as paused for us.
      percent = 1;
    }

    return 200 * Math.log(percent) / Math.log(200);
  }

  function fromLogSpeed(logSpeed) {
    let percent = Math.exp(Math.log(200) * logSpeed / 200);

    if (percent <= 1) {
      percent = 0;
    }

    return percent;
  }

  var _default = _component.default.extend({
    layout: _timeControl.default,
    classNames: ['eat-time-control'],
    speedPercent: 100,
    motionService: (0, _service.inject)('-ea-motion'),
    logSpeed: (0, _object.computed)('speedPercent', function () {
      return toLogSpeed(this.get('speedPercent'));
    }),
    tickMarks: (0, _object.computed)(function () {
      return [{
        value: 0,
        text: 'Paused'
      }, {
        value: 5,
        text: '5%'
      }, {
        value: 10,
        text: '10%'
      }, {
        value: 25,
        text: '25%'
      }, {
        value: 50,
        text: '50%'
      }, {
        value: 100,
        text: '100%'
      }].map(entry => {
        entry.position = (0, _template.htmlSafe)(`left: ${toLogSpeed(entry.value) / 2}%`);
        return entry;
      });
    }),

    didInsertElement() {
      let logSpeed = this.get('logSpeed'); // weirdly, ember is not initializing this for me correctly

      this.element.querySelector('input').value = logSpeed;
    },

    willDestroyElement() {
      if (this.time) {
        this.time.finished();
        this.time = null;
      }
    },

    updateLogSpeed(event) {
      this._setSpeed(fromLogSpeed(event.target.valueAsNumber));
    },

    tickMarkChosen(tickMark) {
      this._setSpeed(tickMark.value);
    },

    _setSpeed(speed) {
      this.get('_speedSetter').perform(speed);
    },

    _speedSetter: (0, _emberAnimated.task)(function* (speed) {
      if (speed === 100) {
        // at normal speed, we want to disable our time control
        // entirely. This means we won't intefere with things like
        // acceptance tests that may already want to take over the
        // timing.
        //
        // But if there's an animation in progress, we must wait until
        // it's done. Otherwise there will be a jarring jump from our
        // fake time to the real time.
        if (this.time) {
          this.time.runAtSpeed(1);
          this.set('speedPercent', speed);
          yield this.get('motionService.waitUntilIdle').perform();
          this.time.finished();
          this.time = null;
        }
      } else {
        if (!this.time) {
          this.time = new _timeControl2.default();
        }

        if (speed === 0) {
          this.time.pause();
        } else {
          this.time.runAtSpeed(speed / 100);
        }

        this.set('speedPercent', speed);
      }
    }).restartable()
  });

  _exports.default = _default;
});