define("ember-animated/components/animated-each", ["exports", "@ember/object/computed", "@ember/object", "@ember/service", "@ember/component", "ember-animated/-private/ember-scheduler", "ember-animated/-private/scheduler", "ember-animated", "ember-animated/-private/transition-context", "ember-animated/-private/sprite", "ember-animated/-private/ember-internals", "ember-animated/-private/partition", "ember-animated/-private/child", "assert-never", "ember-animated/templates/components/animated-each"], function (_exports, _computed, _object, _service, _component, _emberScheduler, _scheduler, _emberAnimated, _transitionContext, _sprite, _emberInternals, _partition, _child, _assertNever, _animatedEach) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _class2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
    A drop in replacement for `{{#each}}` that animates changes to a list.
    ```hbs
    {{#animated-each items use=transition duration=2000 as |item|}}
      <div data-test-item={{item}} onclick={{action removeItem item}}>
        {{item}}
      </div>
    {{/animated-each}}
    ```
    ```js
    import Component from '@ember/component';
    import move from 'ember-animated/motions/move';
    import { fadeOut } from 'ember-animated/motions/opacity';
  
    export default Component.extend({
      init() {
        this._super();
        this.items = ['A', 'B', 'C', 'D', 'E'];
      },
  
      * transition({ keptSprites, removedSprites }) {
        for (let sprite of keptSprites) {
          move(sprite);
        }
  
        for (let sprite of removedSprites) {
          fadeOut(sprite);
        }
      },
  
      removeItem(item){
        this.set('items', this.items.filter(i => i !== item));
      }
    });
    ```
    @class animated-each
    @public
  */
  let AnimatedEach = (_dec = (0, _service.inject)('-ea-motion'), _dec2 = (0, _object.computed)('watch'), _dec3 = (0, _object.computed)('duration'), _dec4 = (0, _object.computed)('items.[]', 'group'), _dec5 = (0, _computed.alias)('animate.isRunning'), _dec6 = (0, _object.computed)('key'), _dec7 = (0, _emberScheduler.task)(function* (transition, firstTime) {
    let {
      parent,
      currentSprites,
      insertedSprites,
      keptSprites,
      removedSprites
    } = yield this.get('startAnimation').perform(transition, (0, _scheduler.current)());
    let {
      matchingAnimatorsFinished
    } = yield this.get('runAnimation').perform(transition, parent, currentSprites, insertedSprites, keptSprites, removedSprites, firstTime);
    yield this.get('finalizeAnimation').perform(insertedSprites, keptSprites, removedSprites, matchingAnimatorsFinished);
  }).restartable(), _dec8 = (0, _emberScheduler.task)(function* (transition, animateTask) {
    // we remember the transition we're using in case we need to
    // recalculate based on other animators potentially moving our DOM
    // around
    this._lastTransition = transition; // Reset the sprite lists. These are component state mostly
    // because beginStaticMeasurement needs to be able to put
    // everything into static positioning at any point in time, so
    // that any animation that's starting up can figure out what the
    // DOM is going to look like.

    let keptSprites = this._keptSprites = [];
    let removedSprites = this._removedSprites = [];
    let insertedSprites = this._insertedSprites = []; // Start by locating our current sprites based off the actual DOM
    // elements we contain. This records their initial positions.

    let {
      currentSprites,
      parent
    } = this._findCurrentSprites(); // Warn the rest of the universe that we're about to animate.


    this.get('motionService').willAnimate({
      task: animateTask,
      duration: this.get('durationWithDefault'),
      component: this,
      children: this._renderedChildren
    }); // Make all our current sprites absolutely positioned so they won't move during render.

    currentSprites.forEach(sprite => sprite.lock()); // Wait for Ember to render our latest state.

    yield (0, _emberAnimated.afterRender)();
    return {
      parent,
      currentSprites,
      insertedSprites,
      keptSprites,
      removedSprites
    };
  }), _dec9 = (0, _emberScheduler.task)(function* (transition, parent, currentSprites, insertedSprites, keptSprites, removedSprites, firstTime) {
    // fill the keptSprites and removedSprites lists by comparing what
    // we had in currentSprites with what is still in the DOM now that
    // rendering happened.
    this._partitionKeptAndRemovedSprites(currentSprites); // perform static measurement. The motionService coordinates this
    // because all animators need to be simultaneously put into their
    // static state via beginStaticMeasurement and endStaticMeasurement.


    yield* this.get('motionService').staticMeasurement(() => {
      // we care about the final position of our own DOM parent. That
      // lets us nest motions correctly.
      if (parent && !parent.finalBounds) {
        parent.measureFinalBounds();
      }

      for (let element of this._ownElements()) {
        // now is when we find all the newly inserted sprites and
        // remember their final bounds.
        if (!currentSprites.find(sprite => sprite.element === element)) {
          if (!parent) {
            parent = _sprite.default.offsetParentEndingAt(element);
          }

          let sprite = _sprite.default.positionedEndingAt(element, parent);

          sprite.owner = this._elementToChild.get(element);
          sprite.hide();
          insertedSprites.push(sprite);
        }
      } // and remember the final bounds of all our kept sprites


      keptSprites.forEach(sprite => sprite.measureFinalBounds());
    }); // at this point we know all the geometry of our own sprites. But
    // some of our sprites may match up with sprites that are entering
    // or leaving other simulatneous animators. So we hit another
    // coordination point via the motionService

    let {
      farMatches,
      matchingAnimatorsFinished,
      beacons
    } = yield this.get('motionService').get('farMatch').perform((0, _scheduler.current)(), insertedSprites, keptSprites, removedSprites); // TODO: This is best effort. The parent isn't necessarily in
    // the initial position at this point, but in practice if people
    // are properly using animated-containers it will be locked into
    // that position. We only need this if there were no elements to
    // begin with. A better solution would figure out what the
    // offset parent *would* be even when there are no elements,
    // based on our own placeholder comment nodes.

    if (parent && !parent.initialBounds) {
      parent.measureInitialBounds();
    }

    let [sentSprites, unmatchedRemovedSprites] = (0, _partition.default)(removedSprites, sprite => {
      let other = farMatches.get(sprite);

      if (other) {
        sprite.endAtSprite(other);

        if (other.revealed && !sprite.revealed) {
          sprite.startAtSprite(other);
        }

        return true;
      }

      return false;
    }); // if any of our inserted sprites have matching far away sprites,
    // they become receivedSprites and they get initialBounds
    // (derived from their far away matching sprite) and motion
    // continuity via `startAtSprite`.

    let [receivedSprites, unmatchedInsertedSprites] = (0, _partition.default)(insertedSprites, sprite => {
      let other = farMatches.get(sprite);

      if (other) {
        sprite.startAtSprite(other);
        return true;
      }

      return false;
    });
    let [matchedKeptSprites, unmatchedKeptSprites] = (0, _partition.default)(keptSprites, sprite => {
      let other = farMatches.get(sprite);

      if (other) {
        if (other.revealed && !sprite.revealed) {
          sprite.startAtSprite(other);
        }

        return true;
      }

      return false;
    }); // let other animators make their own partitioning decisions
    // before we start hiding the sent & received sprites yield

    yield (0, _emberAnimated.microwait)();
    matchedKeptSprites.forEach(s => s.hide());
    sentSprites.forEach(s => s.hide()); // By default, we don't treat sprites as "inserted" when our
    // component first renders. You can override that by setting
    // initialInsertion=true.

    if (firstTime && !this.get('initialInsertion')) {
      // Here we are effectively hiding the inserted sprites from the
      // user's transition function and just immediately revealing
      // them in their final positions instead.
      unmatchedInsertedSprites.forEach(s => s.reveal());
      unmatchedInsertedSprites = [];
    } // if we are interrupted after this point, any removed children that didn't
    // actually undergo a motion will be immediately destroyed.


    this._renderedChildrenStartedMoving = true; // Early exit if nothing is happening.

    if (!transition || unmatchedInsertedSprites.length === 0 && unmatchedKeptSprites.length === 0 && unmatchedRemovedSprites.length === 0 && sentSprites.length === 0 && receivedSprites.length === 0 && matchedKeptSprites.length === 0) {
      return {
        matchingAnimatorsFinished
      };
    }

    let context = new _transitionContext.default(this.get('durationWithDefault'), unmatchedInsertedSprites, // user-visible insertedSprites
    unmatchedKeptSprites, // user-visible keptSprites
    unmatchedRemovedSprites, // user-visible removedSprites
    sentSprites, // user-visible sentSprites
    receivedSprites.concat(matchedKeptSprites), // user-visible receivedSprites
    beacons, sprite => this._motionStarted(sprite, cycle), sprite => this._motionEnded(sprite, cycle));
    let cycle = this._cycleCounter++;
    yield* (0, _transitionContext.runToCompletion)(context, transition);
    return {
      matchingAnimatorsFinished
    };
  }), _dec10 = (0, _emberScheduler.task)(function* (insertedSprites, keptSprites, removedSprites, matchingAnimatorsFinished) {
    yield matchingAnimatorsFinished; // The following cleanup ensures that all the sprites that will
    // stay on the page after our animation are unlocked and
    // revealed. We may have already revealed most of them, but if an
    // inserted sprite was never subject to a motion it will appear
    // here, and if a previous transition was interrupted before an
    // inserted sprite could be revealed, it could have become a kept
    // sprite for us.

    keptSprites.forEach(sprite => {
      sprite.unlock();
      sprite.reveal();
    });
    insertedSprites.forEach(sprite => {
      sprite.unlock();
      sprite.reveal();
    });
    this._keptSprites = null;
    this._removedSprites = null;
    this._insertedSprites = null;

    if (removedSprites.length > 0) {
      // trigger a rerender to reap our removed children
      this.notifyPropertyChange('renderedChildren'); // wait for the render to happen before we allow our animation
      // to be done

      yield (0, _emberAnimated.afterRender)();
    }
  }), (_class = (_temp = _class2 = class AnimatedEach extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "layout", _animatedEach.default);

      _defineProperty(this, "tagName", '');

      _initializerDefineProperty(this, "motionService", _descriptor, this);

      _defineProperty(this, "items", void 0);

      _defineProperty(this, "group", void 0);

      _defineProperty(this, "duration", void 0);

      _defineProperty(this, "use", void 0);

      _defineProperty(this, "rules", void 0);

      _defineProperty(this, "initialInsertion", false);

      _defineProperty(this, "finalRemoval", false);

      _defineProperty(this, "key", void 0);

      _defineProperty(this, "watch", void 0);

      _defineProperty(this, "_elementToChild", new WeakMap());

      _defineProperty(this, "_prevItems", []);

      _defineProperty(this, "_prevSignature", []);

      _defineProperty(this, "_firstTime", true);

      _defineProperty(this, "_inserted", false);

      _defineProperty(this, "_renderedChildren", []);

      _defineProperty(this, "_renderedChildrenStartedMoving", false);

      _defineProperty(this, "_cycleCounter", 0);

      _defineProperty(this, "_keptSprites", null);

      _defineProperty(this, "_insertedSprites", null);

      _defineProperty(this, "_removedSprites", null);

      _defineProperty(this, "_lastTransition", null);

      _defineProperty(this, "_ancestorWillDestroyUs", false);

      _initializerDefineProperty(this, "isAnimating", _descriptor2, this);

      _initializerDefineProperty(this, "animate", _descriptor3, this);

      _initializerDefineProperty(this, "startAnimation", _descriptor4, this);

      _initializerDefineProperty(this, "runAnimation", _descriptor5, this);

      _initializerDefineProperty(this, "finalizeAnimation", _descriptor6, this);
    }

    init() {
      super.init();
      this.get('motionService').register(this).observeDescendantAnimations(this, this.maybeReanimate) // TODO: shouldn't need this cast
      .observeAncestorAnimations(this, this.ancestorIsAnimating);

      this._installObservers();
    }

    _installObservers() {
      let key = this.get('key');

      if (key != null && key !== '@index' && key !== '@identity') {
        // eslint-disable-next-line ember/no-observers
        this.addObserver(`items.@each.${key}`, this, this._invalidateRenderedChildren);
      }

      let deps = this._deps;

      if (deps) {
        for (let dep of deps) {
          // eslint-disable-next-line ember/no-observers
          this.addObserver(`items.@each.${dep}`, this, this._invalidateRenderedChildren);
        }
      }
    }

    get _deps() {
      let w = this.watch; // Firefox has an `Object.prototype.watch` that can troll us here

      if (typeof w === 'string') {
        return w.split(/\s*,\s*/);
      }

      return undefined;
    }

    get durationWithDefault() {
      let d = this.duration;

      if (d == null) {
        return 500;
      } else {
        return d;
      }
    }

    _invalidateRenderedChildren() {
      this.notifyPropertyChange('renderedChildren');
    }

    _identitySignature(items, getKey) {
      if (!items) {
        return [];
      }

      let deps = this._deps;
      let signature = [];

      for (let i = 0; i < items.length; i++) {
        let item = items[i];
        signature.push(getKey(item, i));

        if (deps) {
          for (let j = 0; j < deps.length; j++) {
            let dep = deps[j];
            signature.push((0, _object.get)(item, dep));
          }
        }
      }

      return signature;
    }
    /* eslint-disable ember/require-computed-property-dependencies, ember/no-side-effects */
    // turning off these two rules for this method because we manage our own
    // internal state and know what we're doing.
    // this is where we handle most of the model state management. Based on the
    // `items` array we were given and our own earlier state, we update a list of
    // Child models that will be rendered by our template and decide whether an
    // animation is needed.


    get renderedChildren() {
      let firstTime = this._firstTime;
      this._firstTime = false;
      let getKey = this.get('keyGetter');
      let oldChildren = this._renderedChildren;
      let oldItems = this._prevItems;
      let oldSignature = this._prevSignature;
      let newItems = this.get('items');

      let newSignature = this._identitySignature(newItems, getKey);

      let group = this.group || '__default__';
      this._prevItems = newItems ? newItems.slice() : [];
      this._prevSignature = newSignature;

      if (!newItems) {
        newItems = [];
      }

      let oldIndices = new Map();
      oldChildren.forEach((child, index) => {
        oldIndices.set(child.id, index);
      });
      let newIndices = new Map();
      newItems.forEach((item, index) => {
        newIndices.set(getKey(item, index), index);
      });
      let newChildren = newItems.map((value, listIndex) => {
        let id = getKey(value, listIndex);
        let index = oldIndices.get(id);

        if (index != null) {
          let child = new _child.default(group, id, value, listIndex);
          child.state = 'kept';
          return child;
        } else {
          return new _child.default(group, id, value, listIndex);
        }
      }).concat(oldChildren.filter(child => (!child.shouldRemove || !this._renderedChildrenStartedMoving) && newIndices.get(child.id) == null).map(child => {
        child.flagForRemoval();
        return child;
      }));
      this._renderedChildren = newChildren; // in general, a removed sprite that didn't run a motion in the previous
      // animation gets instantly removed at the start of the next animation. But
      // there is a possible race condition if we recompute before the user's
      // transition even has a chance to begin. So this flag protects the removed
      // sprites until we can hand them off to the user's transition.

      this._renderedChildrenStartedMoving = false;

      if ( // @ts-ignore: untyped FastBoot global
      typeof FastBoot === 'undefined' && !isStable(oldSignature, newSignature)) {
        let transition = this._transitionFor(firstTime, oldItems, newItems);

        this.get('animate').perform(transition, firstTime);
      }

      return newChildren;
    }
    /* eslint-enable ember/require-computed-property-dependencies, ember/no-side-effects */


    get keyGetter() {
      return (0, _emberInternals.keyForArray)(this.key);
    }

    didInsertElement() {
      this._inserted = true;
    }

    *_ownElements() {
      if (!this._inserted) {
        return;
      }

      let {
        firstNode,
        lastNode
      } = (0, _emberInternals.componentNodes)(this);
      let node = firstNode;

      while (node) {
        if (node.nodeType === Node.ELEMENT_NODE) {
          yield node;
        }

        if (node === lastNode) {
          break;
        }

        node = node.nextSibling;
      }
    } // This gets called by the motionService when another animator calls
    // willAnimate from within our descendant components.


    maybeReanimate() {
      if (this.get('animate').isRunning && !this.get('startAnimation').isRunning) {
        // A new animation is starting below us while we are in
        // progress. We should interrupt ourself in order to adapt to
        // the changing conditions.
        this.get('animate').perform(this._lastTransition);
      }
    }

    ancestorIsAnimating(ourState) {
      if (ourState === 'removing' && !this._ancestorWillDestroyUs) {
        // we just found out we're probably getting destroyed. Abandon
        // ship!
        this._ancestorWillDestroyUs = true;

        this._letSpritesEscape();
      } else if (ourState !== 'removing' && this._ancestorWillDestroyUs) {
        // we got a reprieve, our destruction was cancelled before it
        // could happen.
        this._ancestorWillDestroyUs = false; // treat all our sprites as re-inserted, because we had already handed them off as orphans

        let transition = this._transitionFor(this._firstTime, [], this._prevItems);

        this.get('animate').perform(transition);
      }
    }

    _letSpritesEscape() {
      let transition = this._transitionFor(this._firstTime, this._prevItems, []);

      let removedSprites = [];
      let parent;

      for (let element of this._ownElements()) {
        if (!parent) {
          parent = _sprite.default.offsetParentStartingAt(element);
        }

        let sprite = _sprite.default.positionedStartingAt(element, parent);

        sprite.owner = this._elementToChild.get(element);
        removedSprites.push(sprite);
      }

      this.get('motionService').matchDestroyed(removedSprites, transition, this.get('durationWithDefault'), this.get('finalRemoval'));
    }

    willDestroyElement() {
      // if we already got early warning, we already let our sprites escape.
      if (!this._ancestorWillDestroyUs) {
        this._letSpritesEscape();
      }

      this.get('motionService').unregister(this).unobserveDescendantAnimations(this, this.maybeReanimate).unobserveAncestorAnimations(this, this.ancestorIsAnimating);
    } // this gets called by motionService when we call
    // staticMeasurement. But also whenever *any other* animator calls
    // staticMeasurement, even if we're in the middle of animating.


    beginStaticMeasurement() {
      if (this._keptSprites) {
        this._keptSprites.forEach(sprite => sprite.unlock());

        this._insertedSprites.forEach(sprite => sprite.unlock());

        this._removedSprites.forEach(sprite => sprite.display(false));
      }
    }

    endStaticMeasurement() {
      if (this._keptSprites) {
        this._keptSprites.forEach(sprite => sprite.lock());

        this._insertedSprites.forEach(sprite => sprite.lock());

        this._removedSprites.forEach(sprite => sprite.display(true));
      }
    }

    _findCurrentSprites() {
      let currentSprites = [];
      let parent;

      for (let element of this._ownElements()) {
        if (!parent) {
          parent = _sprite.default.offsetParentStartingAt(element);
        }

        let sprite = _sprite.default.positionedStartingAt(element, parent);

        currentSprites.push(sprite);
      }

      return {
        currentSprites,
        parent
      };
    }

    _partitionKeptAndRemovedSprites(currentSprites) {
      currentSprites.forEach(sprite => {
        if (!sprite.element.parentElement) {
          // our currentSprites list was created based on what was in
          // DOM before rendering. Now we are looking after
          // rendering. So some of the removed sprites may have been
          // garbage collected out (based on the logic in
          // renderedChildren()). If so, they will no longer be in the
          // DOM, and we filter them out here.
          return;
        }

        let child = this._elementToChild.get(sprite.element);

        sprite.owner = child;

        if (this._ancestorWillDestroyUs) {
          this._removedSprites.push(sprite);
        } else {
          switch (child.state) {
            case 'kept':
              this._keptSprites.push(sprite);

              break;

            case 'removing':
              this._removedSprites.push(sprite);

              break;

            case 'new':
              // This can happen when our animation gets restarted due to
              // another animation possibly messing with our DOM, as opposed
              // to restarting because our own data changed.
              this._keptSprites.push(sprite);

              break;

            default:
              throw (0, _assertNever.default)(child.state);
          }
        }
      });
    } // The animate task is split into three subtasks that represent
    // three distinct phases. This is necessary for the proper
    // coordination of multiple animators.
    //
    //   1. During `startAnimation`, we ignore notifications about
    //   descendant animations (see maybeReanimate), because we're still
    //   waiting for Ember to finish rendering anyway and we haven't
    //   kicked off our own animation.
    //
    //   2. During `runAnimation`, other animators will know that we are
    //   actually still animating things, so if they are entangled with
    //   us they should not finalize. (They get entangled via farMatch,
    //   meaning some of their sprites and some of our sprites match
    //   up).
    //
    //   3. During `finalizeAnimation`, we are waiting for our entangled
    //   animators that are still in `runAnimation`, then we are
    //   cleaning up our own sprite state.
    //


    _motionStarted(sprite, cycle) {
      sprite.reveal();
      sprite.owner.block(cycle);
    }

    _motionEnded(sprite, cycle) {
      sprite.owner.unblock(cycle);
    }

    _transitionFor(firstTime, oldItems, newItems) {
      let rules = this.get('rules');

      if (rules) {
        return rules({
          firstTime,
          oldItems,
          newItems
        });
      } else {
        return this.get('use');
      }
    }

  }, _defineProperty(_class2, "positionalParams", ['items']), _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "motionService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "_deps", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "_deps"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "durationWithDefault", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "durationWithDefault"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "renderedChildren", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "renderedChildren"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isAnimating", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "keyGetter", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "keyGetter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "maybeReanimate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "maybeReanimate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "ancestorIsAnimating", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "ancestorIsAnimating"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "animate", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "startAnimation", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "runAnimation", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "finalizeAnimation", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AnimatedEach;

  function isStable(before, after) {
    if (before.length !== after.length) {
      return false;
    }

    for (let i = 0; i < before.length; i++) {
      if (before[i] !== after[i]) {
        return false;
      }
    }

    return true;
  }
});