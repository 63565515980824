define("ember-model-validator/decorators/model-validator", ["exports", "@ember/object", "ember-model-validator/decorators/core-validator"], function (_exports, _object, _coreValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function modelValidator(Class) {
    var _class;

    let ModelValidator = (0, _coreValidator.default)(_class = class ModelValidator extends Class {
      clearErrors() {
        this._internalModel.getRecord().errors._clear();

        (0, _object.set)(this, 'validationErrors', {});
        (0, _object.set)(this, 'isValidNow', true);
      }

      pushErrors(errors) {
        let store = this.store;
        let stateToTransition = this.isNew ? 'created.uncommitted' : 'updated.uncommitted';
        this.transitionTo(stateToTransition);
        let recordModel = this.adapterDidInvalidate ? this : this._internalModel;
        store.recordWasInvalid(recordModel, errors, 'error');
      }

    }) || _class;

    return ModelValidator;
  }

  var _default = modelValidator;
  _exports.default = _default;
});