define("ember-model-validator/decorators/object-validator", ["exports", "ember-data", "@ember/object", "ember-model-validator/decorators/core-validator"], function (_exports, _emberData, _object, _coreValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function objectValidator(Class) {
    var _class;

    let ObjectValidator = (0, _coreValidator.default)(_class = class ObjectValidator extends Class {
      constructor() {
        super(...arguments);

        _defineProperty(this, "errors", _emberData.default.Errors.create());
      }

      clearErrors() {
        (0, _object.set)(this, 'errors', _emberData.default.Errors.create());
        (0, _object.set)(this, 'validationErrors', {});
        (0, _object.set)(this, 'isValidNow', true);
      }

      pushErrors(errors) {
        for (let attribute in errors) {
          let messages = errors[attribute];
          this.errors.add(attribute, messages);
        }
      }

      _modelRelations() {}

    }) || _class;

    return ObjectValidator;
  }

  var _default = objectValidator;
  _exports.default = _default;
});