define("ember-animated/domrect-polyfill", [], function () {
  "use strict";

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // Polyfill DOMRect
  // It's not available on Edge or IE11
  function nonEnumerable(target, propertyKey) {
    let descriptor = Object.getOwnPropertyDescriptor(target, propertyKey) || {};

    if (descriptor.enumerable != false) {
      descriptor.enumerable = false;
      Object.defineProperty(target, propertyKey, descriptor);
    }
  }

  let DOMRectPolyfill = (_class = class DOMRectPolyfill {
    static fromRect() {
      var _rect$x, _rect$y, _rect$width, _rect$height;

      let rect = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new this((_rect$x = rect.x) !== null && _rect$x !== void 0 ? _rect$x : 0, (_rect$y = rect.y) !== null && _rect$y !== void 0 ? _rect$y : 0, (_rect$width = rect.width) !== null && _rect$width !== void 0 ? _rect$width : 0, (_rect$height = rect.height) !== null && _rect$height !== void 0 ? _rect$height : 0);
    }

    constructor(x, y, width, height) {
      _initializerDefineProperty(this, "x", _descriptor, this);

      _initializerDefineProperty(this, "y", _descriptor2, this);

      _initializerDefineProperty(this, "width", _descriptor3, this);

      _initializerDefineProperty(this, "height", _descriptor4, this);

      if (x != null) {
        this.x = x;
      }

      if (y != null) {
        this.y = y;
      }

      if (width != null) {
        this.width = width;
      }

      if (height != null) {
        this.height = height;
      }
    } // DOMRect's properties are all non-enumerable


    get top() {
      return this.y;
    }

    get right() {
      return this.x + this.width;
    }

    get bottom() {
      return this.y + this.height;
    }

    get left() {
      return this.x;
    } // But it has a toJSON that does include all the properties.


    toJSON() {
      return {
        x: this.x,
        y: this.y,
        width: this.width,
        height: this.height,
        top: this.top,
        right: this.right,
        bottom: this.bottom,
        left: this.left
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "x", [nonEnumerable], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "y", [nonEnumerable], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "width", [nonEnumerable], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "height", [nonEnumerable], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  })), _class);

  if (typeof window !== 'undefined' && !window.DOMRect) {
    window.DOMRect = DOMRectPolyfill;
  }
});